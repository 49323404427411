import { FC, Dispatch, SetStateAction } from 'react'

import { UserGroupIcon, UserIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { Avatar } from '@mui/material'
import TextField from '@mui/material/TextField'

import Autocomplete from 'src/components/Library/Autocomplete/Autocomplete'
import Button from 'src/components/Library/Button/Button'

interface FormattedOption {
  id: number
  name: string
  group: string
  avatarUrl?: string
}

interface SettingsLayoutCardViewSelectorProps {
  viewAsOptions?: FormattedOption[]
  filterByOption?: FormattedOption
  setFilterByOption: Dispatch<SetStateAction<FormattedOption>>
  filterInputValue?: string | null | undefined
  setFilterInputValue: Dispatch<SetStateAction<string | null | undefined>>
  adminCardsTextSearchValue?: string | null | undefined
  setAdminCardsTextSearchValue: Dispatch<
    SetStateAction<string | null | undefined>
  >
  dragDropEnabled?: boolean
}

const SettingsLayoutCardViewSelector: FC<
  SettingsLayoutCardViewSelectorProps
> = ({
  viewAsOptions,
  filterByOption,
  setFilterByOption,
  filterInputValue,
  setFilterInputValue,
  adminCardsTextSearchValue,
  setAdminCardsTextSearchValue,
}) => {
  return (
    <div className="flex flex-row p-4 px-8 gap-4 justify-end items-end flex-wrap">
      <div className="w-full max-w-[300px] relative">
        <p className="text-sm text-gray-500 mb-1">Search Cards:</p>
        <div className="relative">
          <TextField
            id="adminCardsTextSearch"
            name="Search Cards by text"
            maxRows={4}
            value={adminCardsTextSearchValue}
            size="small"
            onChange={(e) => {
              setAdminCardsTextSearchValue(e.target.value)
            }}
            className="bg-white w-full"
            placeholder="e.g. Airtable"
          />
          {adminCardsTextSearchValue.length > 0 && (
            <Button
              variant="text"
              fullWidth={false}
              className="min-w-[0px] absolute right-2 top-[7px]"
              onClick={() => {
                setAdminCardsTextSearchValue('')
              }}
            >
              <XMarkIcon className="w-4 h-4 text-gray-500" />
              <span className="sr-only">Clear</span>
            </Button>
          )}
        </div>
      </div>

      <div className="w-full max-w-[300px]">
        <p className="text-sm text-gray-500 mb-1 flex items-center justify-start">
          <span>View as:</span>
          {filterInputValue && (
            <b className="text-indigo-600">&nbsp;{filterInputValue}</b>
          )}
        </p>

        <Autocomplete
          id="adminCardsViewAsSelect"
          className="bg-white w-full"
          size="small"
          value={filterByOption}
          groupBy={(option) => option.group}
          onChange={(_, newValue: FormattedOption) => {
            setFilterByOption(newValue)
          }}
          isOptionEqualToValue={(option, value) =>
            option.group === value.group && option.id === value.id
          }
          inputValue={filterInputValue}
          onInputChange={(_, newInputValue) => {
            setFilterInputValue(newInputValue)
          }}
          options={viewAsOptions}
          getOptionLabel={(option: FormattedOption) => option.name ?? ''}
          renderOption={(props, option) => (
            <li {...props}>
              {option.group === 'Membership' && (
                <Avatar
                  className={`h-8 w-8 mr-4 rounded-full ${
                    !option?.avatarUrl && 'bg-indigo-100'
                  }`}
                  src={option?.avatarUrl}
                >
                  <UserIcon className="h-4 w-4 font-thin text-indigo-700" />
                </Avatar>
              )}
              {option.group === 'Membership Group' && (
                <Avatar className="h-8 w-8 mr-4 bg-indigo-100 rounded-full">
                  <UserGroupIcon className="h-4 w-4 font-thin text-indigo-700" />
                </Avatar>
              )}
              {option.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField placeholder="e.g. Sales" {...params} />
          )}
        />
      </div>
    </div>
  )
}

export default SettingsLayoutCardViewSelector
