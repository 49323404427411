import { FC } from 'react'

import { Square2StackIcon, PencilIcon } from '@heroicons/react/24/outline'

import useAnalytics from 'src/lib/hooks/useAnalytics'

import Button from '../../Library/Button/Button'
import { SingleLayoutCardProps } from '../SettingsLayoutCardStack/SettingsLayoutCardStackHelper'

import DoubleVerticalDotsIcon from './DoubleVerticalDotsIcon'

const SettingsLayoutCardStackSingle: FC<SingleLayoutCardProps> = ({
  cardIndex,
  card,
  setCardAdminModalOpen,
  setSelectedCard,
  setModalAction,
  disableDragDrop,
}) => {
  const { trackEvent } = useAnalytics()

  return (
    <div className="flex-grow p-1" data-action="drag-card">
      <div
        className={`p-2 overflow-hidden bg-white relative group shadow  rounded-lg hover:shadow-md hover:cursor-grab flex flex-col text-center  justify-around items-center h-[180px] ${
          disableDragDrop && 'hover:cursor-default'
        }`}
      >
        <div className="absolute m-1 top-0 block w-[95%] group-hover:bg-gray-100 rounded flex justify-between">
          <div className="flex">
            <span className="text-gray-400 group-hover:text-gray-600 p-1 px-2">
              {cardIndex}
            </span>
            <span className="opacity-0 hidden 2xl:block group-hover:opacity-100">
              {!disableDragDrop && <DoubleVerticalDotsIcon />}
            </span>
          </div>
          <div className="flex flex-row opacity-0 group-hover:opacity-100 top-0">
            <Button
              variant="text"
              fullWidth={false}
              className="min-w-[0px] rounded-none"
              onClick={() => {
                setModalAction('duplicate')
                setSelectedCard(card)
                setCardAdminModalOpen(true)
                trackEvent('Settings', 'duplicate card', { card: card.name })
              }}
            >
              <Square2StackIcon className="w-4 h-4 text-gray-600" />
              <span className="sr-only">Duplicate</span>
            </Button>
            <Button
              variant="text"
              fullWidth={false}
              className="min-w-[0px] rounded-none !rounded-r"
              onClick={() => {
                setModalAction('edit')
                setSelectedCard(card)
                setCardAdminModalOpen(true)
                trackEvent('Settings', 'edit card', { card: card.name })
              }}
            >
              <PencilIcon className="w-4 h-4 text-gray-600" />
              <span className="sr-only">Edit</span>
            </Button>
          </div>
        </div>

        <div className="flex items-center flex-col justify-center rounded select-none pt-4">
          {card?.iconStorageObject?.downloadUrl ? (
            <div
              className="mx-auto w-12 h-12 rounded"
              style={{
                backgroundImage:
                  'url(' + card?.iconStorageObject?.downloadUrl + ')',
                backgroundPosition: 'center center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
              }}
            ></div>
          ) : (
            <div className="mx-auto w-12 h-12 bg-gray-200 rounded"></div>
          )}
          <p
            className="text-sm py-2"
            data-testid={`settings-layout-card-stack-single-${card?.name}`}
          >
            {card?.name}
          </p>
        </div>
      </div>
    </div>
  )
}

export default SettingsLayoutCardStackSingle
