import { FC } from 'react'

import {
  FindInformerSectionListQuery,
  FindInformerSectionListQueryVariables,
} from 'types/graphql'

import {
  CellFailureProps,
  CellLoadingProps,
  CellSuccessProps,
} from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'
import SettingsLayoutSection from 'src/components/SettingsLayoutSection/SettingsLayoutSection'

export const beforeQuery = (props) => ({
  variables: props,
  fetchPolicy: 'no-cache',
  nextFetchPolicy: 'no-cache',
})

export const QUERY = gql`
  query FindInformerSectionListQuery($fileNames: [String!]) {
    informerSectionList: informerSectionsByClient {
      id
      name
      order
      isPinnedToSidebar
      clientId
      membershipGroups {
        id
        membershipGroupId
      }
      iconStorageObject {
        id
        downloadUrl
      }
    }
    membershipGroups: membershipGroupsByClient {
      id
      name
    }
    memberships: membershipsByClient(activeOnly: true) {
      id
      user {
        name
        avatarUrl
      }
      membershipGroups {
        membershipGroup {
          name
          id
        }
      }
    }
    globalIcons: globalStorageObjects(fileNames: $fileNames) {
      id
      downloadUrl
    }
  }
`

export const Loading: FC<
  CellLoadingProps<FindInformerSectionListQueryVariables>
> = () => <LoadingSpinner />

type Props = CellSuccessProps<
  FindInformerSectionListQuery,
  FindInformerSectionListQueryVariables
> & {
  onboardingMode?: boolean
}
export type SettingsLayoutSectionRefetch = Props['queryResult']['refetch']
export type InformerSection = Props['informerSectionList'][0]
export type SettingsLayoutIcon = Props['globalIcons'][0]
export type MembershipGroup = Props['membershipGroups'][0]
export type Membership = Props['memberships'][0]

export type InformerSectionList = InformerSection[]
export type MembershipGroups = MembershipGroup[]

export const Empty: FC<Props> = ({
  informerSectionList,
  membershipGroups,
  memberships,
  globalIcons,
  onboardingMode = false,
  queryResult: { refetch },
}) => {
  return (
    <SettingsLayoutSection
      onboardingMode={onboardingMode}
      globalIcons={globalIcons}
      items={informerSectionList}
      memberships={memberships}
      membershipGroups={membershipGroups}
      refetch={refetch}
    />
  )
}

export const Failure: FC<
  CellFailureProps<FindInformerSectionListQueryVariables>
> = ({ error }) => <div style={{ color: 'red' }}>Error: {error.message}</div>

export const Success: FC<Props> = ({
  informerSectionList,
  membershipGroups,
  memberships,
  globalIcons,
  onboardingMode = false,
  queryResult: { refetch },
}) => {
  return (
    <SettingsLayoutSection
      onboardingMode={onboardingMode}
      globalIcons={globalIcons}
      items={informerSectionList}
      memberships={memberships}
      membershipGroups={membershipGroups}
      refetch={refetch}
    />
  )
}
