import { FC } from 'react'

import {
  FindInformerListCardsQuery,
  FindInformerListCardsQueryVariables,
} from 'types/graphql'

import { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'
import { SelectedSection } from 'src/pages/SettingsClientToolsPage/SettingsClientToolsPage'

import {
  InformerSection,
  Membership,
  MembershipGroup,
  SettingsLayoutIcon,
} from '../../SettingsLayoutSection/SettingsLayoutSectionCell/SettingsLayoutSectionCell'
import SettingsLayoutCardStack from '../SettingsLayoutCardStack/SettingsLayoutCardStack'

export const beforeQuery = (props) => ({
  variables: props,
  fetchPolicy: 'no-cache',
  nextFetchPolicy: 'no-cache',
})

export const QUERY = gql`
  query FindInformerListCardsQuery($sectionId: Int!) {
    sectionCards: informerListCardsByClientIdAndSectionId(
      sectionId: $sectionId
    ) {
      id
      name
      iconStorageObject {
        id
        downloadUrl
      }
      url
      order
      clientId
      isAll
      isHidden
      isEmbed
      members {
        id
        informerListCardId
        membershipId
      }
      membershipGroups {
        id
        membershipGroupId
      }
      informerSectionId
    }
  }
`

export const Loading: FC = () => <LoadingSpinner />
type Props = CellSuccessProps<
  FindInformerListCardsQuery,
  FindInformerListCardsQueryVariables
> & {
  selectedSection: SelectedSection
  informerSections: InformerSection[]
  memberships: Membership[]
  membershipGroups: MembershipGroup[]
  globalIcons: SettingsLayoutIcon[]
  onboardingMode?: boolean
}
export type SectionCard = Props['sectionCards'][0]
export type SettingsLayoutCardRefetch = Props['queryResult']['refetch']

// Arrange the cards into an object of sections with matching cards
const groupBy = (arr: SectionCard[], property: string) =>
  arr.reduce(function (returnArr, card) {
    if (card.informerSectionId) {
      if (!returnArr[card[property]]) {
        returnArr[card[property]] = {
          items: [],
          id: card.informerSectionId,
        }
      }
      returnArr[card[property]].items.push(card)
    }
    return returnArr
  }, {})

export const Empty: FC<Props> = ({
  sectionCards,
  informerSections,
  membershipGroups,
  memberships,
  selectedSection,
  globalIcons,
  onboardingMode = false,
  queryResult: { refetch },
}) => {
  return (
    <SettingsLayoutCardStack
      sectionCards={sectionCards}
      selectedSection={selectedSection}
      informerSections={informerSections}
      membershipGroups={membershipGroups}
      members={memberships}
      globalIcons={globalIcons}
      refetch={refetch}
      onboardingMode={onboardingMode}
    />
  )
}

export const Failure: FC<CellFailureProps> = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

type SuccessProps = CellSuccessProps<FindInformerListCardsQuery> & {
  selectedSection: SelectedSection
  informerSections: InformerSection[]
  memberships: Membership[]
  membershipGroups: MembershipGroup[]
  globalIcons: SettingsLayoutIcon[]
  onboardingMode?: boolean
}

export const Success: FC<SuccessProps> = ({
  sectionCards,
  informerSections,
  membershipGroups,
  memberships,
  globalIcons,
  selectedSection,
  onboardingMode = false,
  queryResult: { refetch },
}) => {
  const sectionedObjects = groupBy(sectionCards, 'informerSectionId')

  return (
    <SettingsLayoutCardStack
      sectionCards={sectionCards}
      informerSections={informerSections}
      membershipGroups={membershipGroups}
      members={memberships}
      globalIcons={globalIcons}
      refetch={refetch}
      onboardingMode={onboardingMode}
      sectionedObjects={sectionedObjects}
      selectedSection={selectedSection}
    />
  )
}
