import React from 'react'

import hash from 'object-hash'

export interface Props<TItem> {
  items: TItem[]
  id?: string
  direction?: string
  maxItems: number
  render?(item: TItem, cardOrderNumber: number): any
  onDragEnd?: any
  disableDragDrop?: boolean
}

export const withReactToItemsChange =
  <TItem, P extends Props<TItem>>(
    Component: React.ComponentType<P> | any,
  ): React.ComponentType<P> =>
  (props: P) => <Component key={hash(props.items)} {...props} />
