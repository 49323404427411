import { FC, Dispatch, SetStateAction, useState, useEffect } from 'react'

import { XMarkIcon } from '@heroicons/react/24/outline'
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/solid'
import { TextField } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import {
  getTemplateCards,
  getTemplateCardsVariables,
  type CreateManyInformerListCardMutation,
  type CreateManyInformerListCardMutationVariables,
} from 'types/graphql'

import { useQuery, useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Switch from 'src/components/Library/Switch/Switch'
import Modal from 'src/components/Modal'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import { CREATE_CARD_ITEMS } from 'src/lib/queries/Settings/Layout/SettingsLayout'

import Button from '../../Library/Button/Button'
import { SettingsLayoutCardRefetch } from '../SettingsLayoutCardCell'
import { DefaultCardOption } from '../SettingsLayoutCardStack/SettingsLayoutCardStackHelper'

const GET_TEMPLATE_CARDS = gql`
  query getTemplateCards($isTemplate: Boolean!) {
    informerListCards: informerListCardsByTemplateType(
      isTemplate: $isTemplate
    ) {
      id
      name
      url
      iconStorageObject {
        id
        downloadUrl
      }
    }
  }
`

const getDefaultCardsBySearchFilter = (
  cards: DefaultCardOption[],
  searchText: string,
) => {
  if (!searchText) {
    return cards.map((option) => ({
      id: option.id,
      selected: false,
      details: { ...option },
    }))
  } else {
    const filteredCardsBySearchText = cards.reduce(
      (returnCards, currentCard) => {
        // Push to output if the card name matches search filter
        currentCard?.name.toLowerCase().includes(searchText.toLowerCase()) &&
          returnCards.push({
            id: currentCard.id,
            selected: false,
            details: { ...currentCard },
          })

        return returnCards
      },
      [],
    )

    return filteredCardsBySearchText
  }
}

const findDuplicateCards = (cardsToCreate, existingCards) => {
  // Urls we want to create
  const newUrls = cardsToCreate.map((card) => card?.url)

  // Urls that already exist here
  const existingUrls = existingCards.map((card) => card?.url)

  // Filter and return duplicates
  const duplicateUrls = existingUrls.filter((val) => {
    return newUrls.indexOf(val) !== -1
  })

  return duplicateUrls
}

interface PresetCardSelectOptionProps {
  name?: string
  icon?: string
  selected?: boolean
}

const PresetCardSelectOption: FC<PresetCardSelectOptionProps> = ({
  name,
  icon,
  selected = false,
}) => {
  return (
    <div
      className={`relative flex h-full flex-col items-center justify-end rounded-xl border-2 bg-white px-4 transition-shadow hover:shadow-lg focus:shadow-lg ${
        selected ? 'border-indigo-600' : 'border-gray-200'
      } `}
    >
      <CheckCircleIcon
        className={`absolute right-2 top-2 h-5 w-5 ${
          selected ? 'text-indigo-600' : 'text-gray-200'
        }`}
      />

      <div
        className={`mx-auto mt-8 block h-12 w-12 rounded-xl ${
          !icon && 'bg-gray-200'
        }`}
        style={{
          backgroundImage: 'url(' + icon + ')',
          backgroundPosition: 'center center',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        }}
      ></div>
      <p className="line-clamp-2 h-[56px] select-none pt-2 text-center">
        {name ?? 'Unnamed Option'}
      </p>
    </div>
  )
}

interface SingleCardItem {
  clientId: number
  iconStorageObject?: {
    id: number
    downloadUrl: string
  }
  id: number
  informerSectionId: number
  isAll: boolean
  isEmbed: boolean
  isHidden: boolean
  members?: any[]
  membershipGroups?: any[]
  name: string
  order?: number | null
  url: string
}

interface DefaultCardOptionsModalProps {
  defaultCardOptionsModalOpen: boolean
  setDefaultCardOptionsModalOpen: Dispatch<SetStateAction<boolean>>
  refetch: SettingsLayoutCardRefetch
  selectedSectionId: number | null
  selectedSectionCardCount: number | null
  selectedSectionItems?: SingleCardItem[]
}

export const CardDefaultOptionsModal: FC<DefaultCardOptionsModalProps> = ({
  defaultCardOptionsModalOpen,
  setDefaultCardOptionsModalOpen,
  refetch,
  selectedSectionId,
  selectedSectionCardCount,
  selectedSectionItems,
}) => {
  // Event Tracking
  const { trackEvent } = useAnalytics()

  const [templateCards, setTemplateCards] = useState([])

  const [selectedCardsHasDuplicates, setSelectedCardsHasDuplicates] = useState(
    [],
  )

  useQuery<getTemplateCards, getTemplateCardsVariables>(GET_TEMPLATE_CARDS, {
    variables: { isTemplate: true },
    onCompleted: (data) => {
      if (data.informerListCards.length > 0) {
        setTemplateCards(data.informerListCards)
      }
    },
  })

  // Default options for preset selection
  const [defaultCardOptions, setDefaultCardOptions] = useState(
    getDefaultCardsBySearchFilter(templateCards, ''),
  )

  // States
  const [currentlySaving, setCurrentlySaving] = useState(false)
  const [saveIsDisabled, setSaveIsDisabled] = useState(true)
  const [cardSelectAllValue, setCardSelectAllValue] = useState(false)

  // Cards to create on form submit
  const [createCards, setCreateCards] = useState([])

  // Search Input Value
  const [adminCardsTextSearchValue, setAdminCardsTextSearchValue] = useState('')

  // Checked Items
  const [defaultOptionsCheckedCount, setDefaultOptionsCheckedCount] =
    useState(0)
  const [defaultOptionsChecked, setDefaultOptionsChecked] = useState({})
  const handleDefaultOptionsChecked = (id) => (e) => {
    const { checked } = e.target
    setDefaultOptionsChecked((values) => ({
      ...values,
      [id]: checked,
    }))
  }

  // Filter default cards on search input change
  useEffect(() => {
    setDefaultCardOptions(
      getDefaultCardsBySearchFilter(templateCards, adminCardsTextSearchValue),
    )
  }, [adminCardsTextSearchValue, templateCards])

  // Enable the save button if at least one card is selected.
  useEffect(() => {
    // Calculate number of selected items
    let selectedCount = 0
    for (const [_, value] of Object.entries(defaultOptionsChecked)) {
      if (value === true) {
        selectedCount++
      }
    }
    setDefaultOptionsCheckedCount(selectedCount)

    // Check if the selected cards have urls that already exist in the section
    // Warn the user that they will be creating cards with identical urls
    let orderNumber = 1

    const cardsToCreate = templateCards.reduce((returnCards, currentCard) => {
      if (defaultOptionsChecked[currentCard?.id] === true) {
        returnCards.push({
          name: currentCard?.name,
          iconStorageObjectId: currentCard?.iconStorageObject?.id ?? null,
          url: currentCard?.url,
          order: selectedSectionCardCount + orderNumber,
          isHidden: false,
          isOrganisation: false,
          isAll: true,
          isEmbed: currentCard?.isEmbed ?? false,
          informerSectionId: selectedSectionId,
        })
        orderNumber++
      }
      return returnCards
    }, [])

    // Set Create Cards
    setCreateCards(cardsToCreate)

    if (Object.values(defaultOptionsChecked).includes(true)) {
      setSaveIsDisabled(false)

      // Find the duplicate urls
      setSelectedCardsHasDuplicates(
        findDuplicateCards(cardsToCreate, selectedSectionItems),
      )
    } else {
      setSaveIsDisabled(true)
      setSelectedCardsHasDuplicates([])
    }
  }, [defaultOptionsChecked])

  // Close modal and reset options
  useEffect(() => {
    if (!defaultCardOptionsModalOpen) {
      setDefaultOptionsChecked({})
      setAdminCardsTextSearchValue('')
      setCardSelectAllValue(false)
      setCreateCards([])
    }
  }, [defaultCardOptionsModalOpen])

  // Select/Deselect all options
  useEffect(() => {
    if (cardSelectAllValue) {
      // select all
      const selectAllCards = templateCards.reduce(
        (selectedCards, currentCard) => {
          selectedCards[currentCard?.id] = true
          return selectedCards
        },
        {},
      )
      setDefaultOptionsChecked(selectAllCards)
    } else {
      // deselect all
      setDefaultOptionsChecked({})
    }
  }, [cardSelectAllValue])

  // Create the new cards mutation
  const [createManyCardItemsMutation] = useMutation<
    CreateManyInformerListCardMutation,
    CreateManyInformerListCardMutationVariables
  >(CREATE_CARD_ITEMS, {
    onCompleted: async () => {
      toast.success('Card/s Created', {
        duration: 2000,
        className: 'flex-column',
      })

      await refetch()
      setCurrentlySaving(false)
      setDefaultCardOptionsModalOpen(false)
      setCreateCards([])
    },
    onError: (error) => {
      toast.error(' Error creating Card \n \n' + error.message, {
        duration: 5000,
        className: 'flex-column',
      })
      setCurrentlySaving(false)
    },
  })

  // Create the new cards function
  const createDefaultCards = () => {
    // Set Saving true
    setCurrentlySaving(true)

    // Create the default cards
    createManyCardItemsMutation({
      variables: {
        input: createCards,
      },
    })
  }

  return (
    <Modal
      open={defaultCardOptionsModalOpen}
      closeButtonVisible={false}
      className="max-w-2xl !p-0"
      dialogClassName="!bg-white !p-0"
      onClose={null}
    >
      <div id="createCardModal">
        <div className="flex items-center justify-between px-6 py-4 pt-3">
          <h3 className="mb-0 text-lg font-medium leading-6 text-gray-900">
            Create from template
          </h3>
          <Button
            fullWidth={false}
            variant="text"
            className="min-w-[0px]"
            onClick={() => setDefaultCardOptionsModalOpen(false)}
            disabled={currentlySaving}
          >
            <span className="sr-only">Close</span>
            <XMarkIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
          </Button>
        </div>
        <div className="flex flex-row border-t border-gray-200">
          <div className="w-[25%] grow p-6">
            <p className="rounded bg-indigo-100 px-2 py-1 text-indigo-500">
              All cards
            </p>
          </div>
          <div className="w-[75%] grow border-l border-gray-200 pb-0 pt-6">
            <div className="px-6">
              <p className="mb-1 text-sm text-gray-500">Search Cards:</p>
              <div className="relative">
                <TextField
                  id="adminCardsTextSearch"
                  name="Search Cards by text"
                  maxRows={4}
                  value={adminCardsTextSearchValue}
                  size="small"
                  onChange={(e) => {
                    setAdminCardsTextSearchValue(e.target.value)
                  }}
                  className="w-full bg-white"
                  placeholder="e.g. Airtable"
                />
                {adminCardsTextSearchValue.length > 0 && (
                  <Button
                    fullWidth={false}
                    variant="text"
                    className="absolute right-2 top-[6px] min-w-[0px]"
                    onClick={() => {
                      setAdminCardsTextSearchValue('')
                    }}
                  >
                    <XMarkIcon className="h-4 w-4 text-gray-500" />
                    <span className="sr-only">Clear</span>
                  </Button>
                )}
              </div>
            </div>
            <div className="flex items-center justify-between px-6 pt-4">
              <div className="flex items-center">
                <span className="text-sm text-gray-400">Select All:</span>
                <Switch
                  disabled={!defaultCardOptions.length}
                  data-action="pin-section"
                  checked={cardSelectAllValue}
                  onChange={(e) => {
                    setCardSelectAllValue(e.target.checked)
                  }}
                />
              </div>
              <p className="text-sm text-gray-400">
                Selected:&nbsp;
                <span className="font-bold text-indigo-600">
                  {defaultOptionsCheckedCount}
                </span>
              </p>
            </div>

            {selectedCardsHasDuplicates.length > 0 && (
              <div className="px-6 pt-4">
                <div className="rounded-lg bg-yellow-100 p-4 py-2">
                  <div className="flex items-start gap-2">
                    <div>
                      <ExclamationTriangleIcon className="mt-1 h-4 w-4 text-amber-600" />
                    </div>
                    <div className="w-full">
                      <span className="inline break-all">
                        Identical urls found in this section. You will be
                        creating duplicate cards for the following urls.
                      </span>
                      <ul className="mt-1 list-disc pl-4">
                        {selectedCardsHasDuplicates.map((item, key) => (
                          <li key={key} className="break-all">
                            {item}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!defaultCardOptions.length && (
              <p className="max-h-[440px] min-h-[440px] w-full pt-8 text-center text-gray-400">
                No cards found.
              </p>
            )}
            {defaultCardOptions.length > 0 && (
              <div
                className="mt-4 grid max-h-[440px] min-h-[440px] gap-4 overflow-y-scroll px-6 pb-6"
                style={{
                  gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
                  gridTemplateRows: 'repeat(auto-fill, minmax(120px,auto))',
                }}
              >
                {defaultCardOptions.map((option, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          color="primary"
                          checked={defaultOptionsChecked[option.id] ?? false}
                          onClick={handleDefaultOptionsChecked(option.id)}
                          size="small"
                          className="m-0 hidden h-0 w-0"
                        />
                      }
                      className="w-100 ml-0 mr-0 self-stretch"
                      sx={{
                        '& .MuiFormControlLabel-label': {
                          width: '100%',
                          height: '100%',
                          margin: '0 auto!important',
                        },
                      }}
                      label={
                        <PresetCardSelectOption
                          key={index}
                          name={option.details.name}
                          icon={option?.details.iconStorageObject?.downloadUrl}
                          selected={defaultOptionsChecked[option.id]}
                        />
                      }
                    />
                  )
                })}
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center justify-end bg-gray-100 px-6 py-4">
          <div className="flex gap-4">
            <Button
              variant="text"
              fullWidth={false}
              onClick={() => setDefaultCardOptionsModalOpen(false)}
              disabled={currentlySaving}
            >
              Cancel
            </Button>
            <Button
              fullWidth={false}
              buttonDataTestId="informerCardButtonSave"
              data-action={'create'}
              onClick={() => {
                trackEvent('Settings', 'create preset card', {
                  card: 'Preset Cards',
                })

                createDefaultCards()
              }}
              className=""
              type="submit"
              disabled={saveIsDisabled || currentlySaving}
              loading={currentlySaving}
            >
              Create
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
