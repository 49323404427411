import React, { type ReactElement } from 'react'

import hash from 'object-hash'
import {
  DragDropContext,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
} from 'react-beautiful-dnd'

import { ListManagerItem } from './ListManagerItem'

interface Location {
  id: string
  index: number
}

export interface DragAndDropResult {
  source: Location
  destination: Location
}

export interface Chunk<TItem> {
  id: string
  items: TItem[]
}

export interface Props<TItem> {
  chunks: Chunk<TItem>[]
  direction: 'horizontal' | 'vertical'
  render(item: TItem, cardOrderNumber: number): any
  onDragEnd(result: DragAndDropResult): void
  maxItems: number
  disableDragDrop?: boolean
}

const horizontalStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
}

export const DragAndDropWrapper: React.FC<Props<any>> = ({
  onDragEnd,
  maxItems,
  chunks,
  direction,
  render,
  disableDragDrop,
}: Props<any>) => {
  return (
    <DragDropContext
      onDragEnd={mapAndInvoke(onDragEnd)}
      onDragUpdate={mapAndInvokeUpdate()}
    >
      {chunks.map(({ id: droppableId, items }: Chunk<any>, rowIndex) => (
        <Droppable
          key={droppableId}
          droppableId={droppableId}
          direction={direction}
          isDropDisabled={false}
          isCombineEnabled={false}
          ignoreContainerClipping={false}
        >
          {(provided: DroppableProvided, _: DroppableStateSnapshot) => (
            <div
              ref={provided.innerRef}
              style={direction === 'horizontal' ? horizontalStyle : undefined}
              {...provided.droppableProps}
            >
              {items.map((item: any, index: number) => (
                <ListManagerItem
                  key={hash(item)}
                  maxItems={maxItems}
                  row={rowIndex}
                  item={item}
                  index={index}
                  render={render}
                  disableDragDrop={disableDragDrop}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      ))}
    </DragDropContext>
  )
}

function mapAndInvoke(onDragEnd: (result: DragAndDropResult) => void) {
  return function ({ source, destination }: DropResult): void {
    if (destination !== undefined && destination !== null) {
      const result: DragAndDropResult = {
        source: {
          id: source.droppableId,
          index: source.index,
        },
        destination: {
          id: destination.droppableId,
          index: destination.index,
        },
      }
      onDragEnd(result)
    }
  }
}
function mapAndInvokeUpdate() {
  return function ({ destination }: DropResult): void {
    if (destination !== undefined && destination !== null) {
      // do nothing
    }
  }
}
