import React, { FC, SetStateAction, Dispatch } from 'react'

import {
  InformationCircleIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { Tooltip } from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import useAnalytics from 'src/lib/hooks/useAnalytics'

import Button from '../Library/Button/Button'

interface SectionCardsHeaderProps {
  title: string
  setCardAdminModalOpen: Dispatch<SetStateAction<boolean>>
  setDefaultCardOptionsModalOpen: Dispatch<SetStateAction<boolean>>
  setModalAction: Dispatch<SetStateAction<string | null>>
  dragDropEnabled?: boolean
}

const SectionCardsHeader: FC<SectionCardsHeaderProps> = ({
  title,
  setCardAdminModalOpen,
  setDefaultCardOptionsModalOpen,
  setModalAction,
  dragDropEnabled,
}) => {
  const { trackEvent } = useAnalytics()

  // Context/Dropdown Menu state and event handlers
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <div className="p-8 pb-0">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <div className="rounded-full bg-indigo-100 p-3">
            <UserGroupIcon className="h-8 w-8 font-thin text-indigo-700" />
          </div>
          <p className="text-xl">{title}</p>
          {!dragDropEnabled && (
            <div className="relative">
              <Tooltip
                placement="top"
                arrow
                title={
                  <small className="text-xs">
                    <b>Note: </b>You cannot reorder with drag and drop while
                    search filters are active.
                  </small>
                }
              >
                <span className="flex flex-row items-center gap-1 text-orange-600">
                  <InformationCircleIcon className="h-5 w-5" />
                </span>
              </Tooltip>
            </div>
          )}
        </div>
        {setCardAdminModalOpen && (
          <div className="flex gap-4">
            <Button
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              buttonDataTestId="cardContextMenuButton"
              data-action="card-context-menu"
              endIcon={
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
              }
            >
              Create New
            </Button>
            <Menu
              id="cardContextMenu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transitionDuration={0}
              elevation={3}
              sx={{
                marginTop: '6px',
                padding: 0,
                borderRadius: '10px',
                '& ul': {
                  padding: 0,
                  '& li': {
                    paddingTop: '10px',
                    paddingBottom: '10px',
                  },
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  setModalAction('create')
                  setCardAdminModalOpen(true)
                  trackEvent('Settings', 'create new card', { section: title })

                  handleClose()
                }}
                disableRipple
                id="card-create-blank"
                data-action="create-card"
              >
                Blank Card
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setDefaultCardOptionsModalOpen(true)
                  trackEvent(
                    'Settings',
                    'create new card from default option',
                    { section: title },
                  )

                  handleClose()
                }}
                disableRipple
                id="card-create-default"
                data-action="create-default"
              >
                From Template Library
              </MenuItem>
            </Menu>
          </div>
        )}
      </div>
    </div>
  )
}

export default SectionCardsHeader
