import React, { FC } from 'react'

import hash from 'object-hash'
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd'

export interface ListManagerItemProps {
  id?: string
  item: any
  index: number
  render(item: any, cardOrderNumber: number): any
  row: number
  maxItems: number
  disableDragDrop?: boolean
}

export const ListManagerItem: FC<ListManagerItemProps> = ({
  item,
  index,
  render,
  row,
  maxItems,
  disableDragDrop,
}) => {
  // UI indicator for position in the grid
  const cardOrderNumber = row * maxItems + index + 1

  // Tailwind does not like dynamic custom classes - they do not compute
  const calcMaxWidth = maxItems === 4 ? 'max-w-[25%]' : 'max-w-[14%]'

  return (
    <Draggable
      draggableId={hash(item)}
      index={index}
      isDragDisabled={disableDragDrop}
    >
      {(provided: DraggableProvided, _: DraggableStateSnapshot) => (
        <div
          className={`w-full ${calcMaxWidth}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {render(item, cardOrderNumber)}
        </div>
      )}
    </Draggable>
  )
}
