import { Chunk } from '../withMaxItems'

export function splitItems(
  maxItems: number,
  items: unknown[],
  createId: () => string,
): Chunk[] {
  const slicedItems: unknown[][] = sliceIntoItems(maxItems, items)
  return slicedItems.map(mapToChunk(createId))
}

function sliceIntoItems(maxItems: number, items: unknown[]): unknown[][] {
  const numberOfSlices: number = Math.ceil(items.length / maxItems)
  // eslint-disable-next-line prefer-spread -- TODO: Fix this
  const sliceIndexes: number[] = Array.apply(null, Array(numberOfSlices)).map(
    (_: unknown, index: number) => index,
  )
  return sliceIndexes.map((index: number) =>
    items.slice(index * maxItems, index * maxItems + maxItems),
  )
}

function mapToChunk(createId: () => string) {
  return function (items: unknown[]): Chunk {
    return {
      id: createId(),
      items,
    }
  }
}
