import { FC, useEffect, useState } from 'react'

import { PlusIcon } from '@heroicons/react/24/solid'
import { DropResult } from 'react-beautiful-dnd'
import {
  UpdateInformerSectionsMutation,
  UpdateInformerSectionsMutationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import useAnalytics from 'src/lib/hooks/useAnalytics'
import { UPDATE_SECTIONS } from 'src/lib/queries/Settings/Layout/SettingsLayout'
import { SelectedSection } from 'src/pages/SettingsClientToolsPage/SettingsClientToolsPage'

import Button from '../Library/Button/Button'
import SettingsLayoutCardCell from '../SettingsLayoutCard/SettingsLayoutCardCell/'

import SettingsLayoutDragDrop from './SettingsLayoutDragDrop'
import {
  InformerSection,
  MembershipGroup,
  SettingsLayoutSectionRefetch,
  SettingsLayoutIcon,
  Membership,
} from './SettingsLayoutSectionCell/SettingsLayoutSectionCell'
import SettingsLayoutSectionModal from './SettingsLayoutSectionModal'

type Props = {
  refetch: SettingsLayoutSectionRefetch
  memberships: Membership[]
  membershipGroups: MembershipGroup[]
  items: InformerSection[]
  globalIcons: SettingsLayoutIcon[]
  onboardingMode?: boolean
}

export type ModalAction = 'create' | 'edit' | ''

const SettingsLayoutSection: FC<Props> = ({
  memberships,
  membershipGroups,
  refetch,
  items,
  globalIcons,
  onboardingMode = false,
}) => {
  // Select Input Options for Membership Groups
  const selectFromMembershipGroups = membershipGroups.map((membership) => {
    return { membershipGroupId: membership.id, name: membership.name }
  })

  // This tech stack is created for every client
  // Auto select this if in onboarding mode
  const techStackFound = items.find((item) => {
    return item.name === 'Technology Stack'
  })

  let defaultSection: SelectedSection = null

  if (onboardingMode && techStackFound) {
    defaultSection = {
      id: techStackFound.id,
      name: techStackFound.name,
    }
  }

  const [selectedSection, setSelectedSection] =
    useState<SelectedSection>(defaultSection)

  const [editingSection, setEditingSection] = useState<InformerSection>(null)

  // List of sections for admin panel
  const [sectionItems, setSectionItems] = useState(items)

  const [modalAction, setModalAction] = useState<ModalAction>('')
  const [sectionModalOpen, setSectionModalOpen] = useState(false)

  const openEditSectionModal = () => {
    setModalAction('edit')
    setSectionModalOpen(true)
  }

  const openCreateSectionModal = () => {
    setModalAction('create')
    setSectionModalOpen(true)
  }

  const [updateSectionsMutation] = useMutation<
    UpdateInformerSectionsMutation,
    UpdateInformerSectionsMutationVariables
  >(UPDATE_SECTIONS, {
    onCompleted: async (sectionName) => {
      toast.success('Sections Order Saved', {
        duration: 2000,
        className: 'flex-column',
      })
      await refetch()

      if (sectionName.updateInformerSections) {
        const sectionOrder = sectionName.updateInformerSections
          .map((x) => x.name)
          .join(', ')
        // DD = Drag and Drop, SO = section order. We can only feed 100 characters into the label of GA.
        trackEvent('Settings', 'DD HS Sections', { SO: sectionOrder })
      }
    },
    onError: (error) => {
      toast.error(error.message, {
        duration: 5000,
        className: 'flex-column',
      })
    },
  })

  const updateSections = async (ids, inputArray) => {
    await updateSectionsMutation({
      variables: {
        ids: ids,
        inputArray: inputArray,
      },
    })
  }

  const reorder = <T,>(list: T[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  function onDragEnd(result: DropResult) {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const startIndex: number = result.source.index
    const endIndex: number = result.destination.index

    const items = reorder(sectionItems, startIndex, endIndex)

    setSectionItems(items)

    // Get the order of ids
    const itemIdsToUpdate = items.map((item) => {
      return item.id
    })

    // Get the order update value
    const itemOrderToUpdate = itemIdsToUpdate.map((_item, index) => {
      // Adding 1 to index so order starts at 1
      return { order: index + 1 }
    })

    // Save the new order
    updateSections(itemIdsToUpdate, itemOrderToUpdate)
  }

  useEffect(() => {
    if (!sectionModalOpen) {
      setModalAction('')
    }
  }, [sectionModalOpen])

  useEffect(() => {
    setSectionItems(items)
  }, [items])

  const { trackEvent } = useAnalytics()

  return (
    <>
      <div
        className={`flex flex-col ${
          onboardingMode ? 'bg-white' : 'justify-between bg-gray-100 p-2 pt-4'
        }`}
      >
        {!onboardingMode && (
          <>
            <div className="h-100 flex w-[320px] flex-col justify-center">
              <div className="flex w-full flex-col space-y-4 overflow-hidden rounded border border-gray-200 bg-white">
                <p className="bold border-b border-gray-200 bg-gray-50 p-3 text-sm">
                  Sections
                </p>
                {!sectionItems.length && (
                  <p className="mb-0 px-3 text-sm text-gray-500">
                    There are no sections. Add a section, and create cards to
                    display them on the home page!
                  </p>
                )}
                <SettingsLayoutDragDrop
                  onDragEnd={onDragEnd}
                  sectionItems={sectionItems}
                  selectedSection={selectedSection}
                  setSelectedSection={setSelectedSection}
                  setEditingSection={setEditingSection}
                  openEditSectionModal={openEditSectionModal}
                />
              </div>
            </div>
            <div className="sticky bottom-0 bg-gray-100 py-4">
              <Button
                type="button"
                data-action="add-section"
                buttonDataTestId="add-section"
                onClick={() => {
                  openCreateSectionModal()
                }}
                startIcon={
                  <PlusIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                }
              >
                Add section
              </Button>
            </div>
          </>
        )}
      </div>

      <div className="flex-grow overflow-y-scroll">
        {!selectedSection && (
          <div className="flex h-full w-full items-center justify-center">
            <p className="text-center text-gray-500">
              Select a section on the left to edit its cards.
              <br />
              Click &lsquo;Add Section&rsquo; to create a new section
            </p>
          </div>
        )}
        {selectedSection && (
          <SettingsLayoutCardCell
            selectedSection={selectedSection}
            sectionId={selectedSection?.id}
            informerSections={items}
            memberships={memberships}
            membershipGroups={membershipGroups}
            globalIcons={globalIcons}
            onboardingMode={onboardingMode}
          />
        )}
      </div>

      <SettingsLayoutSectionModal
        sectionModalOpen={sectionModalOpen}
        setSectionModalOpen={setSectionModalOpen}
        modalAction={modalAction}
        globalIcons={globalIcons}
        selectFromMembershipGroups={selectFromMembershipGroups}
        editingSection={editingSection}
        setEditingSection={setEditingSection}
        refetch={refetch}
        setSelectedSection={setSelectedSection}
      />
    </>
  )
}

export default SettingsLayoutSection
