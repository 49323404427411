import { FC } from 'react'

import Button from '../Library/Button/Button'

interface HeroCardProps {
  onClick?: () => void
  title?: string
  subtitle?: string
  backgroundImageUrl?: string
  className?: string
}

interface HeroCardParentElementProps extends HeroCardProps {
  children?: JSX.Element[]
}

const HeroCardParentElement: FC<HeroCardParentElementProps> = ({
  onClick,
  className,
  children,
}) => {
  return onClick ? (
    <Button
      variant="text"
      onClick={onClick}
      className={className + ' items-start text-gray-800'}
    >
      {children}
    </Button>
  ) : (
    <div className={className}>{children}</div>
  )
}

const HeroCard: FC<HeroCardProps> = ({
  onClick,
  title,
  subtitle,
  backgroundImageUrl,
  className,
}) => {
  return (
    <HeroCardParentElement
      onClick={() => {
        onClick()
      }}
      className={`p-10 bg-white rounded-xl min-h-[400px] flex overflow-hidden border border-solid border-gray-100 justify-end text-left flex-col w-full xl:w-1/2 relative transition-all shadow-md hover:shadow-lg hover:bg-white ${
        className ?? ''
      }`}
      backgroundImageUrl={backgroundImageUrl}
    >
      {backgroundImageUrl && (
        <img
          src={backgroundImageUrl}
          className="opacity-[.04] rounded-xl object-cover absolute w-full h-full top-0 left-0"
          alt=""
        />
      )}
      <h2 className="select-none  text-xl mb-1">{title ?? ''}</h2>
      <p className="select-none text-md text-gray-400">{subtitle ?? ''}</p>
    </HeroCardParentElement>
  )
}

export default HeroCard
